<script setup lang="ts">
import type { FormKitNode } from '@formkit/core'

const emit = defineEmits<{
  confirm: [value: { optionExercisedExecutionDate: string | Date | null }]
  close: []
}>()

const dayjs = useDayjs()

// FORM REF
const form = ref<{ node: FormKitNode }>()

// SUBMIT HANDLER
const onSubmit = ({
  optionExercisedExecutionDate,
}: {
  optionExercisedExecutionDate: string | Date | null
}) => {
  emit('confirm', { optionExercisedExecutionDate })
}
</script>

<template>
  <ModalBase
    title="Option Exercise"
    primary-button-text="Confirm"
    secondary-button-text="Back"
    modal-class="max-w-[650px] bg-gray-850 border-gray-750 dark:bg-gray-850 dark:border-gray-800"
    :close-on-click="false"
    @confirm="form?.node.submit()"
    @close="$emit('close')"
  >
    <div>
      <p class="text-center font-bold">
        You are exercising an option through a Variation.
      </p>
    </div>

    <FormKit
      ref="form"
      type="form"
      :actions="false"
      form-class="p-4 mt-4 rounded-lg bg-gray-850"
      :value="{ optionExercisedExecutionDate: dayjs.utc().toISOString() }"
      @submit="onSubmit"
    >
      <div class="grid w-full grid-cols-1 gap-4">
        <FormKit
          type="datepicker"
          name="optionExercisedExecutionDate"
          label="Execution Date"
          outer-class="w-full"
          label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
          validation="required"
          utc="preserve"
        />
        <Dropzone disabled />
      </div>
    </FormKit>
  </ModalBase>
</template>
